<template>
  <div class="page-wrapper">
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>风险检测</el-breadcrumb-item>
          <el-breadcrumb-item>电子税局账号密码填写</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>

      <div class="main-top">
        <main-header>
          <template slot="title">风险检测</template>
        </main-header>
      </div>
    </div>
    <div class="content-body">
      <template>
        <iframe class="fetchBaseUrl" :src="fetchBaseUrl"></iframe>
      </template>
    </div>
  </div>
</template>

<script>
import { getAuthStatus, getAuthStatus2, genTestReportAi } from "@/api/api";
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";

export default {
  data() {
    return {
      postMsg: 100,
      fetchBaseUrl: ""
    };
  },
  components: {
    BreadCrumb,
    MainHeader,
  },
  async mounted() {
    this.fetchUrl();
    //
    window.addEventListener("message", (e) => {
      if (e.data == "v2-submit-success") {
        this.postMsg = 101;
      }
    });
    // 轮询
    this.checkTimer = window.setInterval(() => {
      setTimeout(this.checkAuto(), 0);
    }, 10000);
  },
  methods: {
    // 轮询方法
    checkAuto() {
      getAuthStatus2(this.$route.query.uuid).then((res) => {
        if (res.code == 200) {
          let authorized = res.data;
          if (authorized) {
            window.clearInterval(this.checkTimer);
            this.postMsg = 101;
          }
        }
      });
    },
    fetchUrl() {
      let baseEnv = process.env.VUE_APP_PDF_ROOT_FETCH;
      let data = {
        companyName: this.$route.query.companyName,
        companyTaxNo: this.$route.query.taxNo,
        companyNo: this.$route.query.taxNo,
        preInspectId: this.$route.query.uuid,
      };
      this.fetchBaseUrl =
        baseEnv + "oemV2/" + this.$route.query.zq + "/auth?version=2&info=" + JSON.stringify(data) + '&from=shuijuban';
    },
    checkOld() {
      const loading = this.$loading({
        lock: true,
        text: "正在提交数据！",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      genTestReportAi({
        tag: 14,
        programId: this.$route.query.uuid,
        zq: this.$route.query.zq,
        taxNo: this.$route.query.taxNo,
      }).then((res) => {
        if (res.code == 200) {
          loading.close();
          this.$router.push({
            name: "test-InvoiceUpload",
            params: {
              uuid: this.uuid,
            },
          });
        } else {
          loading.close();
          //如果遇到"该服务暂不可用"就弹出错误信息并返回首页
          this.$alert(res.message, "错误", {
            confirmButtonText: "确定",
            type: "error",
            callback: (action) => {
              this.$router.push({ name: "homePage" });
            },
          });
        }
      });
    },
  },
  watch: {
    postMsg(newdatapostMsg, olddatapostMsg) {
      this.checkOld();
      window.clearInterval(this.checkTimer);
    },
  },
  destroyed() {
    window.clearInterval(this.checkTimer);
  },
};
</script>

<style lang="stylus" scoped>
.modify-table-wrapper {
  margin-top: 30px;
}

.correctDialog {
  padding: 0;

  >>> .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }
}

.tipDialog {
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    box-sizing: border-box;

    .img {
      width: 174px;
      flex-shrink: 0;
    }

    .right-wrapper {
      margin-left: 50px;

      .title {
        font-weight: bold;
        font-size: 18px;
        color: #062340;
      }

      .desc {
        margin-top: 16px;
        color: #6b7897;
        font-size: 16px;
        line-height: 1.5;
      }

      .btn-i-get {
        height: 40px;
        line-height: 40px;
        margin-top: 17px;
      }
    }
  }
}

.the-title {
  margin: 30px 30px 30px 0;
}

.content-body {
  // margin: 30px;
  background: #fff;
  // box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  // border-radius: 0 0 8px 8px;
  // padding: 20px;
  // padding-top: 0;
  // margin-top: 0;
  margin: 0 20px;
  margin-top: 20px;
}

.upload-footer {
  margin-top: 16px;

  .tip {
    float: left;
    color: #FAAD14;
    font-size: 14px;
  }

  .acknowlege, .btn-download {
    background: #fff;
    font-size: 14px;
    float: right;
    color: #2F54EB;
    vertical-align: middle;

    .icon {
      vertical-align: middle;
      font-size: 22px;
    }
  }

  .acknowlege {
    margin-right: 48px;
    cursor: pointer;
    float: right;
  }

  .acknowlege:hover, .btn-download:hover {
  }
}

.table-list {
  margin-top: 18px;
  padding-left: 24px;
}

.footer {
  position: relative;
  // margin-top: 20px;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;

  .btn {
    margin: 0 10px;
  }

  .btn-purge-data {
    color: #ff6464;
  }
}

// >>> .el-dialog__header {
// padding: 0;
// }

// >>> .section-title {
// margin-top: 10px;
// }
.page-wrapper {
  // padding-bottom: 84px;
}

.progressdis {
  // position: absolute;
  width: 10%;
  // margin: 24px auto 45px;
  margin-left: 100px;
  margin-top: 2px;
}

.btn-right-none {
  margin-right: 16px !important;
  width: 180px;
  height: 46px;
  // line-height: 44px;
  box-sizing: border-box;
}

// .report-method {
// margin: 20px;
// }
.nomargin {
  margin: 0 30px;
  padding: 0;
}

.main-top {
  display: flex;
  justify-content: space-between;
}

.content-body-flex {
  display: flex;
  align-item: center;
  justify-content: space-between;
  height: 54px;
  line-height: 54px;
  border-bottom: 1px solid rgba(217, 217, 217, 1);
  margin-bottom: 24px;
  background: rgba(255, 255, 255, 1);
  // padding: 0 20px;
  // margin: 0 20px;
}

.content-body-flexr {
  display: flex;
  align-item: center;
  font-size: 14px;
  color: rgba(47, 84, 235, 1);
}

.content-top-1 {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.content-top-2 {
  margin-right: 24px;
}

.content-body-tab {
  display: flex;
  justify-content: center;
  background: #fff;
  border-bottom: 1px solid #D9D9D9;
  margin: 0 30px 0 30px;
}

.content-body-tabl, .content-body-tabr {
  width: 174px;
  height: 49px;
  line-height: 49px;
  text-align: center;
  background: rgba(255, 255, 255, 1);
  // box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.08);
  // border-radius: 18px 18px 0px 0px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  border: 1px solid #fff;
  border-bottom: none;
}

.content-body-tabl a:hover {
  text-decoration: none;
}

a {
   text-decoration: none;
}

.content-body-tabl {
  margin-right: 24px;
}

.router-link-active {
  text-decoration: none;
}

.zqLocation {
  color: #2F54EB;
  font-weight: 600;
  border: 1px solid #D9D9D9;
  border-radius: 18px 18px 0px 0px;
  border-bottom: none;
}

.arrow-icon {
  margin-top: 24px;
  font-size: 16px;
  font-weight: 600;
  color: rgba(47, 84, 235, 1);
  line-height: 22px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 28px;
}

.uploadFlex {
  display: flex;
  width: 100%;
}

.uploadFlexLeft {
  width: 25%;
  border-right: 1px solid rgba(217, 217, 217, 1);
  padding-right: 32px;
  box-sizing: border-box;
}

.uploadFlexRight {
  width: 75%;
}

.uploadFlexLefts {
  border-bottom: none;
  margin-bottom: 0;
}

.tables {
  position: absolute;
  top: 40%;
  left: 50%;
  margin-left: -550px;
  margin-top: -300px;
  width: 1158px;
  height: 700px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 2000;
  padding-bottom: 30px;
  overflow-y: auto;
}

.content {
  width: 1136px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 0 auto;
}

.title {
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  text-align: center;
  margin: 24px 0;
}

.closes {
  position: absolute;
  right: 25px;
  top: 17px;
  font-size: 32px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.containertables {
  // display: fixed;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // background: rgba(0, 0, 0, 0.04);
}

.process1 {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.process2 {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
}

.processLImg {
  position: relative;
  bottom: 1px;
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.processContainer {
  display: flex;
  align-item: center;
  position: relative;
  top: 6px;
}

.process-status {
  width: 120px;
  height: 2px;
  background: rgba(217, 217, 217, 1);
  position: relative;
  top: 8px;
  margin: 0 24px;
}

.title-content {
  // width: 1260px;
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

>>> .bread-crumb-wrapper {
  margin-top: 0;
}

.progressUpload {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 2022;
}

.progressBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 638px;
  height: 406px;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.processText {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #2F54EB;
  line-height: 28px;
  margin-top: 25px;
}

.processTexted {
  color: #52C41A;
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  line-height: 28px;
  margin-top: 25px;
}

>>> .el-progress--circle .el-progress__text, .el-progress--dashboard .el-progress__text {
  top: 80%;
}

.undraw_upload {
  position: absolute;
  width: 180px;
  height: 132px;
  top: 90px;
}

.processTextedNo {
  visibility: hidden;
}

.data-loading-wrapper {
  height: 722px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .text {
    margin-top: 47px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    line-height: 25px;
  }
}

.changeUserInfo1 {
  color: #999;
  cursor: pointer;
}

.el-alert-warning {
  padding: 24px 78px;
  background: #fff;
}

.el-alert-warningText {
  height: 52px;
  line-height: 52px;
  background: #FBFBFB;
  padding: 0 16px;
  color: rgba(0,0,0,0.6000);
}

.new-next-btn {
  width: 180px;
  height: 46px;
  line-height: 44px;
  background: #2F54EB;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  border: none;
}

.new-next-btn:hover {
  background: #0f3bec;
  color: #fff;
}

.new-next-btn360 {
  width: 360px;
}

.ease {
  transition: all 0.3s;
}

.congtent-title-tab {
  margin: 0 30px;
  height: 54px;
  line-height: 54px;
  background: #FFFFFF;
  box-shadow: inset 0px -1px 0px 0px #D9D9D9;
  border-radius: 8px 8px 0px 0px;
  font-size: 16px;
  font-weight: 600;
  color: rgba(0,0,0,0.8000);
  padding-left: 24px;
  margin-top: 16px;
}

.baseVersion {
  height: 36px;
  line-height: 36px;
  padding: 0 6px;
  background: #2F54EB;
  margin: 4px 6px 0 0;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}

.fetchBaseUrl {
  width: 100%;
  height: 730px;
  border: none;
}
</style>
